header {
  margin-bottom: 25px;
}

#home-search-box {
  min-height: 80vh;

  > img {
    margin: 0 auto;
    width: 250px;
    display: block;
  }

  .grid.queixa {
    > div {
      padding-bottom: 0;
    }
  }

  .filters {
    padding: calc(1rem - 2px);

    > div {
      padding: 2px;
    }
  }

  .grid.categoria {
    padding: calc(1rem - 2px);
    padding-top: 2px;
    > div {
      padding: 2px;
    }
  }
}

.sugestoes {
  li {
    margin-bottom: 2px;
    padding: 2px 3px;
    line-height: 15px;
    list-style: none;
  }
}
