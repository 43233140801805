// Importar fontes
@import url("https://fonts.googleapis.com/css?family=Hind+Guntur");

// Importar variáveis
@import "variables";

// Importar mixins
@import "mixins";

// Importar páginas
@import "/pages/home";

// Importar layouts
// @import 'layouts/login';

// Importar componentes
@import "components/form/form";

* {
  @include mp0;
}

html {
  font-family: "Hind Guntur", sans-serif;
  color: $preto;
}

body {
  background: #fff;
}

header.main {
  padding: 10px 30px;
  text-align: right;
}

footer.main{
  text-align: center;
}

p {
  line-height: 25px;
  font-size: 14px;
}

a{
  cursor: pointer;
}

#root, .modals {
  h1,
  h2,
  h3 {
    margin-bottom: 10px;
  }

  .classificacao {
    position: relative;
    border: 1px solid #000;
    padding: 10px;
    // padding-top: 50px !important;

    p{
      line-height: 15px;
    }

    > header {
      font-weight: bold;
    }

    &.vermelho {
      > header,
      &.background {
        background: #ff7d7d;
      }
    }

    &.laranja {
      > header,
      &.background {
        background: #f3a74f;
      }
    }

    &.amarelo {
      > header,
      &.background {
        background: #ffff9e;
      }
    }

    &.verde {
      > header,
      &.background {
        background: #9dff9d;
      }
    }

    &.azul {
      > header,
      &.background {
        background: #8dc0e1;
      }
    }

    section {
      color: #000;

      .sinal_sintoma {
        margin: 10px 15px;
        font-size: 12px;
      }

      h4 {
        margin: 0;
      }
    }
  }
}

.sinais-box{
  background: #ccc;
}

section {
  margin-bottom: 15px;
}

.field {
  margin-bottom: 0 !important;

  &.flex {
    display: flex;
  }
}

#dados {
  .flex {
    display: flex;

    > div {
      flex: 1 0 auto;
    }
  }
}

.metrics{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: #ccc;

  .loading{
    position: absolute;
    background: #58e053;
    height: 100%;

    .percent{
      position: absolute;
      top: 5px;
      right: 10px;
    }
  }
}

/* ---------- HINTED INPUTS --------- */

.input_hint{
  position: absolute;
  top: 44px;
  background: #ccc;
  width: 100%;
  z-index: 10;
  max-height: 50vh;
  overflow-y: auto;

  > button {
    width: 100%;
    padding: 10px 20px;
    background: #fff;
    border: 1px solid #ccc;
  }
}
