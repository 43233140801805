@import url(https://fonts.googleapis.com/css?family=Hind+Guntur);
header {
  margin-bottom: 25px; }

#home-search-box {
  min-height: 80vh; }
  #home-search-box > img {
    margin: 0 auto;
    width: 250px;
    display: block; }
  #home-search-box .grid.queixa > div {
    padding-bottom: 0; }
  #home-search-box .filters {
    padding: calc(1rem - 2px); }
    #home-search-box .filters > div {
      padding: 2px; }
  #home-search-box .grid.categoria {
    padding: calc(1rem - 2px);
    padding-top: 2px; }
    #home-search-box .grid.categoria > div {
      padding: 2px; }

.sugestoes li {
  margin-bottom: 2px;
  padding: 2px 3px;
  line-height: 15px;
  list-style: none; }

.input_wrap {
  position: relative; }

input[type=text], input[type=password], input[type=search], select {
  height: 45px;
  background: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 13px 23px;
  box-sizing: border-box;
  margin-bottom: 30px;
  transition: .2s all;
  outline: 0; }
  input[type=text]:focus, input[type=password]:focus, input[type=search]:focus, select:focus {
    border-color: #32A9FF; }
  input[type=text].focused_or_filled, input[type=password].focused_or_filled, input[type=search].focused_or_filled, select.focused_or_filled {
    padding: 13px 23px;
    padding-left: 70px; }

select {
  -webkit-appearance: none;
          appearance: none;
  background-position: center right 23px;
  background-repeat: no-repeat; }

label {
  position: absolute;
  top: 13px;
  left: 23px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.3);
  transition: .2s all;
  cursor: pointer; }
  label.focused_or_filled {
    color: #32A9FF;
    left: 20px;
    font-size: .9em;
    font-weight: bold; }
    label.focused_or_filled::after {
      content: ":"; }

.inside_icon {
  position: absolute;
  top: 15px;
  right: 21px;
  line-height: 19px;
  cursor: pointer; }

.btn {
  height: 45px;
  text-align: center;
  color: #FFF;
  background: #32A9FF;
  font-weight: 500;
  line-height: 21px;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  border: none;
  outline: 0; }
  .btn.__full {
    width: 100%; }

* {
  margin: 0;
  padding: 0; }

html {
  font-family: "Hind Guntur", sans-serif;
  color: #2B2B2B; }

body {
  background: #fff; }

header.main {
  padding: 10px 30px;
  text-align: right; }

footer.main {
  text-align: center; }

p {
  line-height: 25px;
  font-size: 14px; }

a {
  cursor: pointer; }

#root h1,
#root h2,
#root h3, .modals h1,
.modals h2,
.modals h3 {
  margin-bottom: 10px; }

#root .classificacao, .modals .classificacao {
  position: relative;
  border: 1px solid #000;
  padding: 10px; }
  #root .classificacao p, .modals .classificacao p {
    line-height: 15px; }
  #root .classificacao > header, .modals .classificacao > header {
    font-weight: bold; }
  #root .classificacao.vermelho > header, #root .classificacao.vermelho.background, .modals .classificacao.vermelho > header, .modals .classificacao.vermelho.background {
    background: #ff7d7d; }
  #root .classificacao.laranja > header, #root .classificacao.laranja.background, .modals .classificacao.laranja > header, .modals .classificacao.laranja.background {
    background: #f3a74f; }
  #root .classificacao.amarelo > header, #root .classificacao.amarelo.background, .modals .classificacao.amarelo > header, .modals .classificacao.amarelo.background {
    background: #ffff9e; }
  #root .classificacao.verde > header, #root .classificacao.verde.background, .modals .classificacao.verde > header, .modals .classificacao.verde.background {
    background: #9dff9d; }
  #root .classificacao.azul > header, #root .classificacao.azul.background, .modals .classificacao.azul > header, .modals .classificacao.azul.background {
    background: #8dc0e1; }
  #root .classificacao section, .modals .classificacao section {
    color: #000; }
    #root .classificacao section .sinal_sintoma, .modals .classificacao section .sinal_sintoma {
      margin: 10px 15px;
      font-size: 12px; }
    #root .classificacao section h4, .modals .classificacao section h4 {
      margin: 0; }

.sinais-box {
  background: #ccc; }

section {
  margin-bottom: 15px; }

.field {
  margin-bottom: 0 !important; }
  .field.flex {
    display: flex; }

#dados .flex {
  display: flex; }
  #dados .flex > div {
    flex: 1 0 auto; }

.metrics {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: #ccc; }
  .metrics .loading {
    position: absolute;
    background: #58e053;
    height: 100%; }
    .metrics .loading .percent {
      position: absolute;
      top: 5px;
      right: 10px; }

/* ---------- HINTED INPUTS --------- */
.input_hint {
  position: absolute;
  top: 44px;
  background: #ccc;
  width: 100%;
  z-index: 10;
  max-height: 50vh;
  overflow-y: auto; }
  .input_hint > button {
    width: 100%;
    padding: 10px 20px;
    background: #fff;
    border: 1px solid #ccc; }

