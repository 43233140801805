// Login

$background_login: #f9f9f9;

// 

$preto: #2B2B2B;
$cinza: rgba(0, 0, 0, 0.54);
$cinza_placeholder: rgba(0, 0, 0, 0.3);

$cor_primaria_neutra: #32A9FF;

$cinza_rodape: #3A3A3A;

$cor_primaria_inema: #86CAB1;

$cor_primaria_sema: #5DA3E3;